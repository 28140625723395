.popup_container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  animation-name: show;
  animation-duration: 0.3s;
  animation-fill-mode: both;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.7;
  }
  .popup {
    background: white;
    position: relative;
    max-width: 450px;
    font-weight: 300;
    font-size: 13px;
    padding: 40px;

    .actions {
      margin-top: 20px;
      display: flex;
      column-gap: 20px;

      .additional_info {
        text-decoration: underline;
        font-weight: 300;
      }
      .accept {
        background: #6ba631;
        color: white;
        padding: 10px;
      }
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
