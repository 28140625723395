.navbar {
    display: flex;
    align-items: center;
    background-color: #13a6ec;

    .navbar__content {
        flex-wrap: wrap;
        justify-content: flex-end;
        display: flex;

        .right_side {
            flex-wrap: wrap;
            display: flex;
        }
        button,
        a {
            font-family: "Roboto Slab", serif;
            font-size: 13px;
            font-weight: 300;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 120px;
            text-decoration: none;
            min-height: 30px;
            color: white;
            border: none;
            border-left: white 1px solid;
            background-color: transparent;
            outline: none;
            cursor: pointer;
            transition: 0.3s;
            padding: 0 8px;

            &:hover {
                background-color: #158ec7;
                transition: 0.3s;
            }
            svg {
                margin-right: 5px;
                width: 15px;
                height: 15px;
            }
        }
        .move_back {
            border-right: white 1px solid;
            border-left: unset;
        }
    }
    .align_right {
        justify-content: space-between;
    }
}
