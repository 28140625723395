/** @format */

.timer,
.time_text {
  font-size: 30px;
  font-weight: 700;
  border: 2px solid #858585;
  background: #525252;
  display: flex;
  flex-wrap: nowrap;

  span {
    padding: 0 6px;
    display: inline-block;
    text-align: center;
    border-right: 2px solid #858585;
    border-left: 2px solid #858585;
  }
}
.time_text {
  font-size: 10px;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  max-width: 280px;
  width: 100%;

  span {
    border: none;
  }
}
