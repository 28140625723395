@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body,
#root {
  margin: 0;
  font-family: "Roboto Slab", serif;
}

body {
  overflow-x: hidden;
}

.container {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

input {
  outline: none;
}

button {
  font-family: "Roboto Slab", serif;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
