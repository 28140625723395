.change_pwd_form {
    width: fit-content;
    margin: 0 auto;

    .header_title {
        font-size: 26px;
        font-weight: 700;
        color: #444;
        text-align: center;
        margin: 20px auto;
    }
    form {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        .change_pwd {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 15px;

            label {
                font-weight: 200;
            }
            input {
                font-weight: 300;
                font-family: "Roboto Slab", serif;
                width: 340px;
                height: 30px;
                padding-left: 4px;
                border: 1px solid gray;
            }
        }
        .success_message {
            color: green;
            text-align: center;
            font-weight: 700;
            font-size: 15px;
        }
        .send {
            font-family: "Roboto Slab", serif;
            font-size: 16px;
            max-width: 175px;
            padding: 20px;
            background-color: #04a4cc;
            margin: 10px auto 30px;
            transition: 0.2s;
            text-transform: uppercase;
            color: white;
            font-weight: 700;
            cursor: pointer;

            &:hover {
                background-color: #016d88;
            }
        }
    }
}
