.creating_folder {
    margin: 50px auto;
    max-width: 450px;
    width: 90%;

    .error_messages {
        text-align: center;
        font-weight: 700;
        margin-bottom: 20px;
        color: red;
        font-size: 15px;
    }
    input {
        margin: 10px 0;
        padding: 0 15px;
        line-height: 30px;
        border: 2px solid #0f88c1;
        width: 100%;
        font-size: 13px;
        font-weight: 300;
        border-radius: 2px;
        border-color: #dfdfdf;
        outline: none;
        box-sizing: border-box;
        font-family: "Roboto Slab", serif;
    }
    .folder_actions {
        display: flex;
        justify-content: center;
        column-gap: 10px;
        margin: 20px 0;

        .cancel_btn {
            border: 1px solid #ff0000;
            background-color: #f04747;

            &:hover {
                background-color: #ff0000;
            }
        }
        .submit_btn {
            border: 1px solid #0f88c1;
            background-color: #13a6ec;

            &:hover {
                background-color: #0f88c1;
            }
        }
    }
}
