.work_container {
  width: 265px;
  font-size: 15px;

  .bg_image {
    background-image: url("../../assets/images/book.png");
  }
  .draggable {
    cursor: grab;
  }
  .work_content {
    max-width: 250px;
    position: relative;
    height: 318px;
    display: flex;
    row-gap: 25px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title {
      text-align: center;
      color: white;
      font-weight: 600;
      font-size: 18px;
      max-width: 210px;
    }
    .img_container {
      // width: 165px;
      text-align: center;

      button {
        background: transparent;

        & > img,
        & > video {
          width: 165px;
          height: 165px;
        }
      }
    }
  }
  .work_img_full_width {
    max-width: 265px;
    height: 200px;

    video,
    img {
      max-height: 150px;
      max-width: 165px;
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;

    .date_view {
      width: 100%;
      max-width: 108px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background: #3da6ec;
      border-radius: 4px;
      color: white;
      font-size: 13px;
      padding: 0 3px;
      height: 25px;

      .date {
        padding-right: 4px;
        height: 100%;
        display: flex;
        align-items: center;
        border-right: 1px solid white;
      }
      .view {
        padding-left: 2px;
      }
    }
    .likes {
      display: flex;
      align-items: flex-end;
      max-width: 40px;
      cursor: pointer;

      img {
        padding-left: 5px;
        max-width: 22px;
      }
    }
    .react-share__ShareButton {
      padding-right: 7px !important;

      .socNet {
        max-width: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 5px 3px;
        font-size: 13px;
        background-color: #476aa9;
        color: white;
        border-radius: 4px;
        cursor: pointer;

        img {
          margin-right: 5px;
        }
      }
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .open,
    .save_book {
      font-family: "Roboto Slab", serif;
      display: inline-block;
      margin: 10px 0;
      width: 48%;
      outline: none;
      border: 2px solid #3da6ec;
      background: #3da6ec;
      color: white;
      border-radius: 3px;
      font-size: 14px;
      padding: 12px 0;
      cursor: pointer;
      text-align: center;
    }
    .full_width {
      width: 100%;
      font-size: 17px;
    }
  }
  .school,
  .mark {
    margin: 5px 0;
    color: #444;
    height: 30px;
    span {
      font-weight: 700;
    }
  }
  .school {
    margin: 5px 0 15px 0;
  }
  .comments {
    margin: auto;
    max-width: unset;
    padding: unset;
  }
}
