.img_viewer_container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  animation-name: show;
  animation-duration: 0.3s;
  animation-fill-mode: both;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.7;
  }
  .popup {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .close {
      font-size: 26px;
      width: 20px;
      height: 15px;
      top: 0;
      right: 3px;
      position: fixed;
      cursor: pointer;
      z-index: 1;
    }

    .img_viewer {
      max-width: 700px;
      max-height: 700px;

      .splide {
        max-width: 700px !important;
      }

      .image__comments {
        display: flex;
      }

      img {
        width: 700px;
        height: 700px;
      }

      .splide__track {
        .splide__list {
          video {
            height: 700px;
            width: 700px;
          }
        }
      }
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1024) {
  .popup {
    display: flex;
    .img_viewer_container .popup .img_viewer img {
      width: 500px !important;
      height: 500px !important;
      margin: 5% 0 !important;
    }

    .splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
      width: 550px !important;
    }
  }
}

@media (max-width: 1024px) {
  .popup {
    display: flex;

    .img_viewer {
      img {
        width: 600px !important;
        height: 600px !important;
        margin: 5% 0 !important;
      }
      video {
        width: 600px !important;
        height: 600px !important;
        margin: 5% 0 !important;
      }
    }

    .splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
      width: 100% !important;
    }
  }
}

@media (max-width: 768px) {
  .popup {
    display: flex;

    .img_viewer {
      img {
        width: 400px !important;
        height: 400px !important;
        margin: 5% 0 !important;
      }
      video {
        width: 400px !important;
        height: 400px !important;
        margin: 5% 0 !important;
      }
    }

    .splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
      width: 100% !important;
    }
  }
}

@media (max-width: 414px) {
  .popup {
    display: flex;
    flex-direction: column;
    top: 5%;
    bottom: 5%;

    .img_viewer {
      img {
        width: 320px !important;
        height: 300px !important;
        margin: 5% 0 !important;
      }
      video {
        width: 320px !important;
        height: 300px !important;
        margin: 5% 0 !important;
      }
    }

    .splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
      width: 100% !important;
    }
  }
}
