.folders_modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  animation-name: show;
  animation-duration: 0.3s;
  animation-fill-mode: both;

  .popup {
    position: relative;
    background-size: 80px 117px;
    background-color: white;
    padding: 0;
    width: 670px;
    max-width: 100%;
    border-radius: 2px;

    .close {
      width: 12px;
      height: 12px;
      top: 3px;
      right: 3px;
      position: absolute;
      cursor: pointer;
    }
    .folders {
      max-height: 350px;
      max-width: 450px;
    }
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
