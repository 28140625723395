.profile {
    .header_title {
        font-size: 26px;
        font-weight: 700;
        color: #444;
        text-align: center;
        margin: 40px auto;
    }

    .registration_wrapper {
        form {
            .delete_account,
            .save_data {
                font-family: "Roboto Slab", serif;
                font-size: 16px;
                max-width: 175px;
                padding: 20px;
                transition: 0.2s;
                margin: 40px 5px;
                font-weight: 700;
                color: white;
                cursor: pointer;
            }
            .delete_account {
                background-color: #fa5252;

                &:hover {
                    background-color: #ff0000;
                }
            }
            .save_data {
                background-color: #04a4cc;

                &:hover {
                    background-color: #016d88;
                }
            }
            hr {
                display: block;
                color: #d7d5d5;
            }
        }
    }
}
