.add_comment {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  // width: 100%;
  column-gap: 8px;

  input {
    max-width: 220px;
    width: 100%;
    height: 26px;
    width: 220px;
    border: 1px solid gray;
  }
  button {
    max-width: 30px;
    width: 100%;
    padding: 0;
    outline: 0;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.error {
  font-size: 14px;
  margin-top: 5px;
  height: 22px;
}
