.cookie_info_container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  animation-name: show;
  animation-duration: 0.3s;
  animation-fill-mode: both;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .popup {
    background: white;
    position: relative;
    max-width: 500px;
    font-weight: 300;
    font-size: 13px;
    padding: 20px;

    .close {
      font-size: 26px;
      width: 20px;
      height: 15px;
      top: 0;
      right: 3px;
      position: absolute;
      cursor: pointer;
      z-index: 1;
    }
    .hide {
      display: none;
    }
    .header {
      margin: 15px 0;
      font-size: 14px;
    }
    .point {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      position: relative;
      line-height: 3;
      margin-top: 25px;
      background: #8080801f;
      padding-right: 15px;

      &::before {
        content: " ";
        width: 6px;
        height: 6px;
        transform: rotateZ(135deg);
        border-top: 2px solid gray;
        border-left: 2px solid gray;
        transition: 0.2s all ease-in-out;
        position: absolute;
        top: 16px;
        left: 10px;
      }
      span {
        margin-left: 28px;
      }
      .status {
        margin-top: 10px;
        line-height: 1.6;

        input[type="checkbox"] {
          display: none;
        }
        label {
          cursor: pointer;
          text-indent: -75px;
          width: 40px;
          height: 20px;
          background: grey;
          display: block;
          border-radius: 100px;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            width: 10px;
            height: 10px;
            background: #fff;
            border-radius: 90px;
            transition: 0.3s;
          }
        }

        input:checked + label {
          background: #4aa847;
        }

        input:checked + label:after {
          left: calc(100% - 5px);
          transform: translateX(-100%);
        }
      }
    }
    .show_text {
      &::before {
        transform: rotateZ(225deg);
      }
    }
    .text {
      padding: 0 15px;
      margin: 10px 0 20px;
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
