.folders {
  display: flex;
  row-gap: 10px;
  flex-direction: column;
  max-height: 350px;
  overflow-y: auto;
  width: 100%;
  margin: 50px auto 0;
  padding-right: 20px;
  .folder {
    margin: 0 10%;
  }

  .header {
    margin: 20px auto 0;
    font-size: 20px;
    text-align: center;
  }
}
@media (max-width: 450px) {
  .folders {
    padding-right: 0;
  }
}
