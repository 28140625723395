.competitionBooks_container {
  font-size: 15px;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  width: 100%;
  grid-gap: 50px;
  padding: 20px 0;
  margin: 20px 0;

  .bg_imageCompetition {
    background-image: url("../../assets/images/book.png");
  }
  .draggableCompetition {
    cursor: grab;
  }
  .description {
    background-color: white;
    width: 200px;
    font-size: 12px;
    position: absolute;
    top: 300px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 5px 5px;
    left: 0;
  }

  .compRewards {
    img {
      width: 100px;
    }
  }
  .work_contentCompetition {
    position: relative;
    height: 318px;
    display: flex;
    row-gap: 25px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 270px;

    .competition_img_one {
      width: 100px;
      position: absolute;
      left: -70px;
      bottom: 0;
    }

    .competition_img_two {
      width: 100px;
      position: absolute;
      left: -70px;
      bottom: 0;
    }

    .competition_img_three {
      width: 100px;
      position: absolute;
      left: -70px;
      bottom: 0;
    }

    .cloud {
      width: 200px;
      position: absolute;
      top: -65px;
      left: -75px;
    }

    .title {
      text-align: center;
      color: white;
      font-weight: 600;
      font-size: 18px;
      width: 200px;
      z-index: 1;
    }
    .img_containerCompetition {
      width: 95%;
      text-align: center;

      button {
        background: transparent;

        & > img,
        & > video {
          width: 80%;
        }
      }
    }
  }

  .secondary {
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      margin-bottom: 10px;
      font-size: 40px;
    }
    img {
      width: 60px;
    }
  }
  .work_img_full_width {
    // max-width: 265px;
    // height: 200px;

    video,
    img {
      max-height: 200px;
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;

    .date_view {
      width: 100%;
      max-width: 108px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background: #3da6ec;
      border-radius: 4px;
      color: white;
      font-size: 13px;
      padding: 0 3px;
      height: 25px;

      .date {
        padding-right: 4px;
        height: 100%;
        display: flex;
        align-items: center;
        border-right: 1px solid white;
      }
      .view {
        padding-left: 2px;
      }
    }
    .likes {
      display: flex;
      align-items: flex-end;
      max-width: 40px;
      cursor: pointer;

      img {
        padding-left: 5px;
        max-width: 22px;
      }
    }
    .react-share__ShareButton {
      padding-right: 7px !important;

      .socNet {
        max-width: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 5px 3px;
        font-size: 13px;
        background-color: #476aa9;
        color: white;
        border-radius: 4px;
        cursor: pointer;

        img {
          margin-right: 5px;
        }
      }
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .open,
    .save_book {
      font-family: "Roboto Slab", serif;
      display: inline-block;
      margin: 10px 0;
      width: 48%;
      outline: none;
      border: 2px solid #3da6ec;
      background: #3da6ec;
      color: white;
      border-radius: 3px;
      font-size: 14px;
      padding: 12px 0;
      cursor: pointer;
      text-align: center;
    }
    .full_width {
      width: 100%;
      font-size: 17px;
    }
  }
  .school,
  .mark {
    margin: 5px 0;
    color: #444;
    width: 20rem;

    span {
      font-weight: 700;
    }
  }
  .comments {
    margin: unset;
    max-width: unset;
    padding: unset;
  }
}

@media (max-width: 768px) {
  .competitionBooks_container {
    display: grid;
    grid-template-columns: repeat(1, auto);
  }
}

@media (max-width: 600px) {
  .competitionBooks_container {
    display: grid;
    grid-template-columns: repeat(1, auto);
  }
}

@media (max-width: 450px) {
  .competitionBooks_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 6%;

    .likes {
      display: none;
    }
  }
}
