.registration_text {
    text-align: center;
    background-color: #13a6ec;
    margin-bottom: 40px;
    padding: 20px 0;

    h1 {
        font-size: 50px;
        font-weight: 900;
        margin: 0;
    }
    span {
        font-size: 20px;
    }
    h2 {
        font-size: 25px;
        color: white;
        font-weight: 700;
        margin: 0;
    }
}
