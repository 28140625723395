.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
}
.add_work {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    animation-name: show;
    animation-duration: 0.3s;
    animation-fill-mode: both;

    .popup {
        position: relative;
        background-size: 80px 117px;
        background-color: #f2f2f2;
        padding: 0;
        width: 540px;
        max-width: 100%;
        border-radius: 2px;

        .head {
            background: #009fe3;
            border: 1px solid #0b648e;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            text-align: center;

            .close {
                width: 12px;
                height: 12px;
                top: 3px;
                right: 3px;
                position: absolute;
                cursor: pointer;
            }
            h2 {
                color: #fff;
                margin: 0;
                font-size: 25px;
                line-height: 1.2;
                padding: 20px;
                font-weight: 700;
            }
        }
        .content {
            form {
                margin: 0 auto;
                max-width: 360px;
                width: 90%;

                p {
                    font-size: 15px;
                    color: #292929;
                    font-weight: 700;
                    text-align: center;
                }
                .error_messages {
                    text-align: center;
                    font-weight: 700;
                    margin-bottom: 20px;
                    font-size: 15px;
                }
                input {
                    padding: 0 15px;
                    line-height: 48px;
                    border: 1px solid #0f88c1;
                    width: 100%;
                    margin: 0 0 20px;
                    font-size: 13px;
                    font-weight: 300;
                    border-radius: 2px;
                    border-color: #dfdfdf;
                    outline: none;
                    box-sizing: border-box;
                    font-family: "Roboto Slab", serif;
                }
                .submit_btn {
                    transition: 200ms all ease-in-out;
                    border-radius: 2px;
                    padding: 0 15px;
                    line-height: 40px;
                    border: 1px solid #0f88c1;
                    width: 100%;
                    margin: 0 0 20px;
                    background-color: #13a6ec;
                    color: #fff;
                    font-size: 18px;
                    font-weight: 700;
                    text-align: center;
                    border-color: transparent;
                    border: none;

                    &:hover {
                        background-color: #0f88c1;
                    }
                }
            }
        }
        .left_hero,
        .right_hero {
            position: absolute;
            bottom: 19px;
            left: 5px;
            width: 80px;
        }
        .right_hero {
            left: unset;
            right: 5px;
        }
    }
}

@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 540px) {
    .add_work {
        .popup {
            .left_hero,
            .right_hero {
                display: none;
            }
        }
    }
}
