.info_table_work {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 440px;
  position: relative;
  background-image: url("../../assets/images/paper.png");
  background-size: contain;
  background-repeat: round;
  margin-top: -1px;
  margin-left: 3%;

  .actions {
    display: flex;
    align-items: center;
    margin: 40px 0;

    .share_via_fb {
      display: flex;
      justify-content: space-evenly;
      width: 130px;
      padding: 7px;
      background-color: #476aa9;
      color: white;
      border-radius: 6px;
      margin: 0 15px;
    }
    .likes {
      font-size: 28px;
      color: #444;
      display: flex;
      align-items: flex-end;
      column-gap: 20px;
      width: 100%;
      cursor: pointer;

      img {
        max-width: 50px;
        padding-left: 40px;
      }
    }
  }
  .add_comment {
    input {
      margin-left: 25px;
      font-family: "Roboto Slab", serif;
      line-height: 2;
      width: 200px;
    }
  }
}

@media (max-width: 1024px) {
  .info_table_work {
    height: 400px !important;
    margin-top: 3%;
  }
}

@media (max-width: 768px) {
  .info_table_work {
    height: 400px !important;
    margin-top: 3%;
  }
}

@media (max-width: 450px) {
  .info_table_work {
    height: 300px !important;
    width: 300px;
  }
}
