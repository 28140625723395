/** @format */

input::-ms-clear {
  display: none;
}
a {
  text-decoration: none;
}
.registration_wrapper {
  margin-top: 20px;
  max-width: 1140px;
  margin: 0 auto;
  text-align: center;
  padding: 0 10px;
  font-weight: 300;

  input {
    padding-left: 3px;
    font-family: "Roboto Slab", serif;
    font-weight: 300;
  }
  .registration_header {
    display: none;
  }
  .account_name_container {
    display: flex;
    max-width: 714px;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;

    input {
      width: 100%;
      max-width: 714px;
      height: 30px;
      margin-top: 5px;
      border: 1px solid gray;
    }
  }
  .age_email_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 714px;
    margin: 0 auto;
    margin-top: 20px;

    .age {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      input {
        width: 340px;
        height: 30px;
        border: 1px solid gray;
      }
    }
    .email {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      input {
        width: 340px;
        height: 30px;
        border: 1px solid gray;
      }
    }
  }
  .pwds_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 714px;
    margin: 0 auto;
    margin-top: 20px;

    .password {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      input {
        width: 340px;
        height: 30px;
        border: 1px solid gray;
      }
    }
    .confirm_password {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      input {
        width: 340px;
        height: 30px;
        border: 1px solid gray;
      }
    }
  }
  .type {
    max-width: 714px;
    margin: 30px auto 0 auto;
    text-align: left;

    span {
      margin-bottom: 10px;
    }
    .radio {
      display: flex;
      align-items: center;
      justify-content: space-between;

      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
      }
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 20px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #444;
      }
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 3px;
        width: 15px;
        height: 15px;
        border: 1px solid #444;
        border-radius: 100%;
        background: #fff;
      }
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        content: "";
        width: 10px;
        height: 10px;
        background: #13a6ec;
        position: absolute;
        top: 6.5px;
        left: 3.3px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
      [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }

      .child {
        display: flex;
        align-items: center;
        font-size: 15px;
      }
      .parent {
        display: flex;
        align-items: center;
        font-size: 15px;
      }
      .teacher {
        display: flex;
        align-items: center;
        font-size: 15px;
      }
    }
  }
  .success_message {
    color: green;
    text-align: center;
    font-weight: 700;
    margin-top: 25px;
    font-size: 15px;
  }
  .send {
    font-family: "Roboto Slab", serif;
    font-size: 16px;
    max-width: 175px;
    padding: 20px;
    background-color: #04a4cc;
    margin: 40px auto;
    transition: 0.2s;
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      background-color: #016d88;
    }
  }
  hr {
    display: none;
  }
}
.error_message {
  color: red;
  margin-top: 20px;
}
@media (max-width: 719px) {
  .registration_wrapper {
    .age_email_container {
      justify-content: center;

      .age {
        width: 100%;

        input {
          width: 100%;
        }
      }
      .email {
        width: 100%;
        margin-top: 10px;

        input {
          width: 100%;
        }
      }
    }
    .pwds_container {
      justify-content: center;

      .password {
        width: 100%;

        input {
          width: 100%;
        }
      }
      .confirm_password {
        width: 100%;
        margin-top: 10px;

        input {
          width: 100%;
        }
      }
    }
  }
}
